import React, { useEffect, useState } from "react";
const queryString = require('query-string');

import { checkAndSetSalesRepId } from "../../utils/salesRep";
import { redirectToPurchase } from "../../utils/stripe";

const RepRedirect = () => {

    // not currently used
    // the offering page is a better display of the offerings, this would go right to the stripe checkout

    const [error, setError] = useState(false);

    useEffect(() => {
        checkAndSetSalesRepId();
        const {priceID, name} = queryString.parse(location.search);
        if (!priceID || !name) {
            setError("There was an error with the QR code. Please tell Chris to fix it.")
        } else {
            redirectToPurchase({priceID, name})
        }
    }, []);

    return <>
        {!error && <p>Redirecting!</p>}
        { error && <p style={{textAlign: "center"}}>{error}</p> }
    </>
};
export default RepRedirect;